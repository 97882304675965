<template>
  <b-row class="blog-list-wrapper mt-1"
  >
    <b-col md="4"
      v-for="index in 3" :key="index"
    >
      <b-img
        :src="imageDefault"
        class="card-img-top"
      />
      <b-card
        tag="article"
        no-body
        class="is-loading"
      >
        <b-card-body>
          <b-card-title></b-card-title>
          <b-media no-body>
            <b-media-aside
              vertical-align="center"
              class="mr-50"
            >
              <b-avatar
                size="24"
                :src="avatarDefault"
              />
            </b-media-aside>
            <b-media-body>
              <small class="text-muted mr-50"></small>
            </b-media-body>
          </b-media>
          <hr>
          <p>
          </p>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BCardText, BButton, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
  },
  data() {
    return {
      /* eslint-disable global-require */
      avatarDefault: '',
      imageDefault: require('@/assets/images/pages/default.png'),
      /* eslint-disable global-require */
    }
  },
}
</script>
