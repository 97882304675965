<template>
  <content class="blog-wrapper">
    <div class="group-area mt-1">
      <h4>Proyek</h4>
      <p>
        Ini adalah Proyek dalam membangun kaum muda Indonesia!!
      </p>
    </div>
    <b-row>
      <b-col
        md="3"
      >
        <b-button
          v-if="$can('create', 'proyek')"
          variant="primary"
          @click="tambah"
          class="mr-1"
        >
          <span class="text-nowrap">Tambah Proyek</span>
        </b-button>
        <b-button
          variant="primary"
          @click="filterShow"
        >
          <feather-icon
            icon="SearchIcon"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="blog-list-wrapper mt-1 mb-1">
      <b-col
        md="12"
      >
        <list-filter
          v-if="isFilterShow"
          @emitFilter="emitFilter"
        />
      </b-col>
    </b-row>
    <b-carousel
      id="carousel-1"
      :interval="10000"
      indicators
      img-height="1080"
      v-if="showSlider()"
    >
      <b-carousel-slide
        v-for="item in projectList"
        :key="item.id"
      >
        <template #img>
          <b-card
            tag="article"
            no-body
          >
            <b-link :to="{ name: 'pages-projek-detail', params: { id: item.id } }">
              <b-img
                v-if="item.image"
                :src="item.image"
                class="card-img-top"
              />
            </b-link>
            <b-card-body>
              <b-card-title>
                <b-link
                  :to="{ name: 'pages-projek-detail', params: { id: item.id } }"
                  class="blog-title-truncate text-body-heading"
                >
                  {{ item.nama }}
                </b-link>
              </b-card-title>
              <b-badge
                v-if="isExist(item.user_proyek)"
                class="mb-2"
                variant="primary"
              >
                Saya terlibat dalam aksi ini.
              </b-badge>
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    :to="{ name: 'pages-profile-detail', params: {id: item.created_by}}"
                    size="24"
                    :src="item.avatar"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted mr-50">by</small>
                  <small>
                    <b-link
                      :to="{ name: 'pages-profile-detail', params: {id: item.created_by}}"
                      class="text-body"
                    >
                      {{ item.created_by_user.name }}
                    </b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ item.datePost }}</small>
                </b-media-body>
              </b-media>
              <hr>
              <div class="d-flex justify-content-between align-items-center">
                <b-link :to="{ path: `/kegiatan/list?proyekId=${item.id}`}">
                  <div class="d-flex align-items-center text-body">
                    <feather-icon
                      icon="ActivityIcon"
                      class="mr-50"
                    />
                    <span class="font-weight-bold">{{ kFormatter(item.totalKegiatan) }} Kegiatan</span>
                  </div>
                </b-link>
                <b-link
                  :to="{ name: 'pages-projek-detail', params: { id: item.id } }"
                  class="font-weight-bold"
                >
                  Lihat Selengkapnya
                </b-link>
                <b-link
                  v-if="canUpdate(item.user_proyek)"
                  :to="{ name: 'pages-proyek-ubah', params: { id: item.id } }"
                  class="font-weight-bold"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </b-link>
                <b-link
                  v-if="$can('proyek', 'delete')"
                  class="font-weight-bold"
                  @click="deleteData(item.id)"
                >
                  <feather-icon
                    icon="Trash-Icon"
                  />
                </b-link>
              </div>
            </b-card-body>
          </b-card>
          <br/>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <b-row
      v-if="showAll()"
      class="blog-list-wrapper"
    >
      <b-col
        v-for="item in projectList"
        :key="item.id"
        md="4"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link :to="{ name: 'pages-projek-detail', params: { id: item.id } }">
            <b-img
              v-if="item.image"
              :src="item.image"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'pages-projek-detail', params: { id: item.id } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ item.nama }}
              </b-link>
            </b-card-title>
            <b-badge
              v-if="isExist(item.user_proyek)"
              class="mb-2"
              variant="primary"
            >
              Saya terlibat dalam aksi ini.
            </b-badge>
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  :to="{ name: 'pages-profile-detail', params: {id: item.created_by}}"
                  size="24"
                  :src="item.avatar"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">by</small>
                <small>
                  <b-link
                    :to="{ name: 'pages-profile-detail', params: {id: item.created_by}}"
                    class="text-body"
                  >
                    {{ item.created_by_user.name }}
                  </b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ item.datePost }}</small>
              </b-media-body>
            </b-media>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <b-link :to="{ path: `/kegiatan/list?proyekId=${item.id}`}">
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="ActivityIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ kFormatter(item.totalKegiatan) }} Kegiatan</span>
                </div>
              </b-link>
              <b-link
                :to="{ name: 'pages-projek-detail', params: { id: item.id } }"
                class="font-weight-bold"
              >
                Lihat Selengkapnya
              </b-link>
              <b-link
                v-if="canUpdate(item.user_proyek)"
                :to="{ name: 'pages-proyek-ubah', params: { id: item.id } }"
                class="font-weight-bold"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-link>
              <b-link
                v-if="$can('proyek', 'delete')"
                class="font-weight-bold"
                @click="deleteData(item.id)"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- pagination -->
      <b-col cols="12"
        v-if="rows"
      >
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            @change="handlePageChange"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <content-loader
      v-if="loaderShow"
    />
  </content>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import {
  BRow, BCol, BCard, BFormInput, BCardText,
  BButton, BCardTitle, BMedia, BAvatar, BMediaAside,
  BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup,
  BInputGroupAppend, BPagination,
  BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import {
  deleteProyek,
  getAllProyek,
} from '@/api/proyek'
import ListFilter from './ListFilter.vue'
import ContentLoader from '../../layouts/Loader.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    // BFormInput,
    // BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    // BBadge,
    // BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    BButton,
    BImg,
    BPagination,
    // ContentWithSidebar,
    ListFilter,
    ContentLoader,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      search_query: '',
      blogList: [],
      projectList: [],
      courseList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 9,
      rows: 0,
      /* eslint-disable global-require */
      avatarDefault: '',
      imageDefault: require('@/assets/images/pages/default.png'),
      /* eslint-disable global-require */
      loaderShow: true,
      isFilterShow: false,
      searchForm: {
        page: 1,
        nama: '',
        private: {
          id: 3,
          label: 'Semua',
        },
        status: {
          id: 'aktif',
          label: 'Aktif',
        },
        only_me: false,
      },
      amres: localStorage.getItem('amres'),
      showAllData: true,
    }
  },
  created() {
    // this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    // this.$http.get('/blog/list/project').then(res => { this.projectList = res.data })
    // this.$http.get('/blog/list/course').then(res => { this.courseList = res.data })
    // this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
  },
  mounted() {
    if (isMobile) {
      this.showAllData = false
    }
    // if (this.amres === 'true') {
    //   // console.log
    // } else {
    //   this.showMsgBoxAmres()
    // }
    try {
      this.show = true
      this.currentPage = 1
      this.getDataPage()
    } catch (error) {
      // console.log(error.response.data)
    }
  },
  methods: {
    async deleteData(param) {
      if (window.confirm('Apakah anda yakin?')) {
        const dataPost = { id: param, soft_delete: 1 }
        const response = await deleteProyek(dataPost)
        if (response.data.message === 'error') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'Tidak dapat menghapus data!',
            },
          })
        } else if (response.data.message === 'success') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sukses',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil dihapus',
            },
          })
          this.$router.go()
        }
      }
    },
    showSlider() {
      return isMobile
    },
    showAll() {
      let returns = true
      if (!isMobile && this.showAllData === true) {
        returns = true
      } else {
        returns = false
      }
      return returns
    },
    async emitFilter(searchForm) {
      this.searchForm = searchForm
      this.getDataPage()
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataPage()
    },
    async getDataPage() {
      this.searchForm.page = this.currentPage
      const response = await getAllProyek(this.searchForm)
      this.projectList = response.data.data
      this.rows = response.data.total
      this.show = false
      this.loaderShow = false
    },
    tambah() {
      this.$router.push('/proyek/tambah')
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    filterShow() {
      if (this.isFilterShow === false) {
        this.isFilterShow = true
      } else {
        this.isFilterShow = false
      }
    },
    isExist(userProyek) {
      // dataMeetup.user_kegiatan && dataMeetup.user_kegiatan[0] &&
      if (userProyek && userProyek[0] && userProyek[0].status_approval_id === 2) {
        return true
      }
      return false
    },
    canUpdate(userProyek) {
      if (this.$can('manage', 'all')) {
        return true
      }
      // dataMeetup.user_kegiatan && dataMeetup.user_kegiatan[0] &&
      if (userProyek && userProyek[0] && this.$can('update', 'proyek') && userProyek[0].status_approval_id === 2) {
        return true
      }
      return false
    },
    showMsgBoxAmres() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxOk('Mohon melengkapi Data Pengguna terlebih dahulu.', {
          title: 'Informasi Kelengkapan',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Tutup',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
